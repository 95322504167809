import axiosInstance from './index';
import { Role } from '@/models/role';

const api = 'api/roles';

class RoleService {

  getRoles() {
    return axiosInstance.get<Role[]>(`${api}`);
  }
  
}

export const roleService = new RoleService();
