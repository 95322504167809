
export default class ObjectModel {
	id: number;				//ид
	deviceNumber: string;	//номер оборудования
	typeDeviceId: number;	//ид типа оборудования
	typeDevice: string;		//тип оборудования
	addressId: number;		//ид адреса
	address: string;		//адрес
	mechanicId: number | null;		//ид механника
	mechanic: string;		//механник
	foremanId: number | null;		//ид прораба
	foreman: string;		//прораб
	activity = true;		//активность
	companyId: number;		
	company: string;
	typeControl: string;
	typeControlId: string;
}


export class ObjectSearchResult {
	id: number;				//ид
	value: string;		//адрес + номер оборудования + тип
	companyId: number;		//ид фирмы
}
