import Company from './company';
import { Role } from './role';


export default class Employee {
	id: number;             //ид
	surname: string;        //фамилия
	name: string;           //имя
	//временное исправление по умолчанию пустая строка чтобы избежать проблем там где не добавили отчество
	patronymic = "";     //отчество
	login: string;			//логин
	password: string;		//пароль
	roleId: number;         //ид должности
	role: Role;      //роль/должность
	activity = true;		//активность
	companies: Array<Company>;
	subscribedToTelegramNotifications: boolean;
	companyId: number;// хз почему было пропущено
	company: string
}
