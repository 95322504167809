import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import City from '../views/City.vue'
import Address from '../views/Address.vue'
import Bids from '../views/Bids.vue'
import Employees from '../views/Employees.vue'
import Objects from '../views/Objects.vue'
import Street from '../views/Street.vue'
import TypeDevice from '../views/Type-device.vue'
import TypeFailure from '../views/Type-failure.vue'
import MonthlyReport from '../views/Monthly-report.vue'
import Company from '../views/Company.vue'
import BidEdit from '../views/BidEdit.vue'

import { authService } from '@/services/authentification.service';
import ReasonFailure from "@/views/ReasonFailures.vue";
import Contacts from "@/views/Contacts.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/', component: Home, name: 'Home',
    children: [
      { path: '', name: 'Dashboard', component: Dashboard, meta: { Name: 'Информационная панель' }},
      { path: '/city', name: 'City', component: City, meta: { Name: 'Города' }},
      { path: '/employees', name: 'Employees', component: Employees, meta: { Name: 'Сотрудники' }},
      { path: '/type-failure', name: 'TypeFailure', component: TypeFailure, meta: { Name: 'Категории неисправностей' }},
      { path: '/street', name: 'Street', component: Street, meta: { Name: 'Улицы' }},
      { path: '/type-device', name: 'TypeDevice', component: TypeDevice, meta: { Name: 'Виды оборудования' }},
      { path: '/address', name: 'Address', component: Address, meta: { Name: 'Адреса' }},
      { path: '/objects', name: 'Objects', component: Objects, meta: { Name: 'Объекты' }},
      { path: '/company', name: 'Company', component: Company, meta: { Name: 'Фирмы' }},
      { path: '/monthly-report', name: 'MonthlyReport', component: MonthlyReport, meta: { Name: 'Месячный отчет' }},
      { path: '/bids', name: 'Bids', component: Bids, meta: { Name: 'Заявки' }},
      { path: '/bidsEdit/:id', name: 'BidsEdit', component: BidEdit, meta: { Name: ' ' }},
      { path: '/reason-failure', name: 'ReasonFailure', component: ReasonFailure, meta: { Name: 'Причины неисправностей' }},
      { path: '/contacts', name: 'Contacts', component: Contacts, meta: { Name: 'Контакты' }},
    ]
  },
  {
    path: '/login', name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '**', redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !authService.isAuthorized) next({ name: 'Login' })
  else next()
})
//localStorage.removeItem('currentUser');
export default router
