

export class ImagesModel{
  public id: number;
  public file: File | null;
  public previewImage: string | ArrayBuffer | null;
  
  //[key: string]: any | null;
  //new Map<string, Person>()
  // constructor(inputFile: File | null){
  //   this.file = inputFile;
  // }
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class ImageLoader extends Vue {
  
  @Prop()
  readonly images: Array<ImagesModel>;

  addFiles() {
    const fileInput = this.$refs.files as HTMLInputElement | null;
    fileInput?.click();
  }

  removeFile(file: ImagesModel){
    const index = this.images.indexOf(file);
    this.images.splice(index, 1);
  }

  handleFilesUpload() {
    const fileInput = this.$refs.files as HTMLInputElement | null;
    if (fileInput && fileInput.files) {
      const uploadedFiles = fileInput.files;
      
      for (let i = 0; i < uploadedFiles.length; i++){
        if(this.images.length === 10){
          break;
        }
        const image = new ImagesModel();
        image.file = uploadedFiles[i];
        this.images.push(image);
      }
      this.getImagePreviews();
    }
  }

  getImagePreviews() {
    for (const key in this.images) {
        const element = this.images[key];
        //пропускать уже загруженные файлы
         if (element.file && /\.(jpe?g|png|gif)$/i.test(element.file.name)){
          const reader = new FileReader();
          // reader.addEventListener(
          //   "load",
          //   function () {
          //     Vue.set(element, 'previewImage', reader.result)
          //   }.bind(this),
          //   false
          // );
          reader.onload = (e) => {
             Vue.set(element, 'previewImage', e.target?.result)
          }
          reader.readAsDataURL(element.file);
       }
    }
  }
    
  
}
