

import { objectService } from "@/services/object.service";
import { accessSettings } from "@/settings/access.settings";

import ObjectModel from "../models/object";
import {Filter, Sort} from "@/models/common";
import { Component, Vue, Watch } from "vue-property-decorator";
import ConfirmComponent from "@/components/ConfirmComponent.vue";
import ObjectEdit from "@/views/ObjectEdit.vue";
import TypeControl from "@/models/type-control";
import {typeControlService} from "@/services/type-control.service";
import { DataOptions } from "vuetify";

@Component({
  components: { ConfirmComponent, ObjectEdit }
})
export default class Objects extends Vue {
  private objects: Array<ObjectModel> = new Array<ObjectModel>();
  private totalObjects = 0;
  private loading = true;

  private enabledEditObject = false;
  private editedItem = new ObjectModel();

  private displayEditDialog = false;
  private displayConfirmDialog = false;

  private pagination = {
    page: 1,
    itemsPerPage: 50,
    pageStart: 0,
    pageStop: 50,
    pageCount: 0,
    itemsLength: 0,
  };

  private options: DataOptions = {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false
  };

  private deleteLoading = false;

  private showFirmFilter = false;
  private showTypeControl = false;

  private headers = [
    { text: "Адрес", value: "address", sortable: true },
    { text: "Тип", value: "typeDevice", sortable: true },
    { text: "Номер", value: "deviceNumber", sortable: true },
    { text: "Механник", value: "mechanic", sortable: true },
    { text: "Прораб", value: "foreman", sortable: true },
    { text: "Активный", value: "activity", sortable: true }
  ];

  private searchCompany = "";
  private searchAddress = "";
  private selectedTypeControl = "";

  private loadingTypeControls = false;
  private typeControls = new Array<TypeControl>();

  private selectedState = "";
  private objectStates = ["Активные", "Не активные"];

  private loadingExcel = false;

  async created() {
    this.showFirmFilter = await accessSettings.canAccess("showFirmFilter");
    const showID = await accessSettings.canAccess("showID");
    this.enabledEditObject = await accessSettings.canAccess("enabledEditObject");
    this.showTypeControl = await accessSettings.canAccess("showTypeControl");

    if(this.showTypeControl){
      this.headers.push({ text: "Управление", value: "typeControl", sortable: true });
    }
    if (this.enabledEditObject) {
      this.headers.push({text: "Действия", value: "actions", sortable: false,});
    }
    if (this.showFirmFilter){
      this.headers.splice(0, 0, { text: "Фирма", value: "company", sortable: true });
    }
    if (showID){
      this.headers.unshift({text: "Ид", sortable: false, value: "id"});
    }
    await this.loadTypeControls();
  }

  async loadTypeControls() {
    try {
      this.loadingTypeControls = true;
      const res = await typeControlService.getTypeControl();
      this.typeControls = res.data;
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.loadingTypeControls = false;
  }

  getFilters(){
    const filters = new Array<Filter>();
    if (this.searchCompany)
      filters.push({field: "company", value: this.searchCompany});

    if (this.searchAddress)
      filters.push({field: "address", value: this.searchAddress});

    if(this.selectedTypeControl)  
      filters.push({field: "typeControl", value: this.selectedTypeControl});

    if(this.selectedState)
      filters.push({field: "activity", value: this.selectedState == "Активные" ? 'true' : 'false'});
    
    return filters;
  }

  getSorts(){
    const {sortBy, sortDesc} = this.options;

    const sortField = sortBy[0]
        ? sortBy[0].slice(0, 1).toUpperCase() + sortBy[0].slice(1)
        : null;
    const sortOrder = sortDesc[0] == false ? 1 : 0;
    const sorts = new Array<Sort>();
    if(sortField != null)
      sorts.push({field: sortField, order: sortOrder});
    return sorts;
  }

  async excelExport() {
    this.loadingExcel = true;

    const filters = this.getFilters();
    const sorts = this.getSorts();

    try {
      const response = await objectService.exportExcel(0, 0, sorts, filters);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Объекты.xlsx');
      document.body.appendChild(link);
      link.click();
    }
    catch (ex) {
      this.$toast.error("Ошибка при экспорте");
    }
    this.loadingExcel = false;
  }

  @Watch("searchCompany")
  @Watch("searchAddress")
  @Watch("selectedTypeControl")
  @Watch("selectedState")
  @Watch("options", {deep: true})
  async loadObjects() {
    const {page, itemsPerPage} = this.options;

    this.loading = true;
    const offset = (page - 1) * itemsPerPage;
    const count = itemsPerPage == -1 ? this.totalObjects : itemsPerPage;
    const filters = this.getFilters();
    const sorts = this.getSorts();

    this.pagination.pageStart = offset;
    this.pagination.pageStop = offset + itemsPerPage;

    try {
      const res = await objectService.getPageData(offset, count, sorts, filters);
      this.totalObjects = res.data.count;
      this.objects = res.data.collection;
      this.pagination.itemsLength = res.data.count;
      this.pagination.pageCount = Math.ceil(res.data.count / 50);
    } catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.loading = false;
  }

  async showEditDialog(item: ObjectModel) {
    await (this as any).$refs.editObject.showEditDialog(item);
  }

  showDeleteDialog(item: ObjectModel) {
    this.editedItem = Object.assign({}, item);
    this.displayConfirmDialog = true;
  }

  async deleteObject() {
    try {
      this.deleteLoading = true;
      const item = this.editedItem;
      await objectService.delete(item.id);
      await this.loadObjects();
    }
    catch (ex) {
      this.$toast.error("Ошибка при удалении");
    }
    this.deleteLoading = false;
    this.displayConfirmDialog = false;
    await this.loadObjects();
  }
}
