
import { Line, mixins } from 'vue-chartjs'
import {Component, Prop, Vue} from "vue-property-decorator";

const { reactiveProp } = mixins;

@Component({
  extends: Line,
  mixins: [reactiveProp]
})
export default class LineChart extends Vue {

  @Prop()
  chartData: object;
  @Prop()
  options: object;

  mounted () {
    (this as any).renderChart(this.chartData, this.options);
  }
  
  public update(){
    (this as any).renderChart(this.chartData, this.options);
  }

}
