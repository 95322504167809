import axios, { AxiosRequestConfig } from 'axios';
import { authService } from './authentification.service';
import router from '@/router';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Expires': '0',
    'Pragma': 'no-cache',
  }
});

//используется для добавления в каждый запрос токена авторизации
//должно инициализироваться до самого vue

const createSetAuthInterceptor = () => (config: AxiosRequestConfig) => {
  if (authService.token) {
    config.headers.Authorization = 'Bearer ' + authService.token;
  }
  else {
    delete config.headers.Authorization;
  }
  return config;
};

const setAuthCb = createSetAuthInterceptor();
axiosInstance.interceptors.request.use(setAuthCb);

//еще один для редиректа на страницу логина при недействительном токене
const UNAUTHORIZED = 401;
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    //console.log(error.toJSON());

    if (error.response) {
      const { status } = error.response;
      if (status === UNAUTHORIZED) {
        authService.logout();
        router.push("Login");
        return Promise.resolve(error.response);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;