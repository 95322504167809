
import {authService} from "@/services/authentification.service";

export class DashboardSettings {
    public userId: string;
    public year?: string;
    public month?: string;
    public typeFailureId?: number;
}

export class DashboardSettingsService{

    async setSettings(year?: string, mounth?: string, typeFailureId?: number) {
        const user = await authService.getCurrentUser();
        //user id as key
        localStorage.removeItem(user.id.toString());
        const settings = new DashboardSettings();
        settings.userId = user.id.toString();
        settings.year = year;
        settings.month = mounth;
        settings.typeFailureId = typeFailureId;
        //console.log("save");
        //console.log(settings);
        localStorage.setItem(settings.userId, JSON.stringify(settings));
    }

    async getSettings() {
        const user = await authService.getCurrentUser();
        const settingsJson = localStorage.getItem(user.id.toString());
        if(settingsJson){
            const settings = JSON.parse(settingsJson);

            //console.log("load");
            //console.log(settings);
            return settings;
        }
        return null;
    }
}

export const dashboardSettingsService = new DashboardSettingsService();