import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ru from 'vuetify/src/locale/ru';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { ru },
      current: 'ru',
    },
    theme: {
      dark: localStorage.getItem("darkTheme") == "true",
    themes: {
      light: {
        bg: '#f5f3f3',
      }
    }
  }
});
