import axiosInstance from './index';
import Bid from '@/models/bid';
import { Filter, Sort} from "@/models/common";
import BaseService from './base.service';
import { BidCommentModel } from '@/models/bid-comment';

class BidService extends BaseService<Bid> {

  getSingle(id: string){
    return axiosInstance.get<Bid>(this.api + '/' + id);
  }

  savePhotos(id: number, files: Array<File>){
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append("photos", file);
    }
    const headers = { 'Content-Type': 'multipart/form-data' };
    const options = { headers: headers };

    return axiosInstance.post(this.api + "/" + id + "/AddPhoto", formData, options);
   }

   deletePhotos(ids: Array<number>) {
    const params = {
      ids:  ids.join(","),
    }
    return axiosInstance.delete("/api/photos", {params: params});
  }
  
  addComment(comment: BidCommentModel){
    return axiosInstance.post("/api/comments", comment);
  }

  updateComment(comment: BidCommentModel){
    return axiosInstance.put("/api/comments/" + comment.id, comment);
  }

  removeComment(id: number){
    return axiosInstance.delete("/api/comments/" + id);
  }

  exportExcel(offset: number, count: number, sorts: Sort[], filters: Filter[]){
      const params = {
          offset:  offset,
          count: count,
          sorts: JSON.stringify(sorts),
          filters: JSON.stringify(filters)
      }
      return axiosInstance.get(this.api + '/Export', {params: params, responseType: 'blob'});
  }

}

export const bidService = new BidService("/bids");
