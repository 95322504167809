import Address from '@/models/address';
import BaseService from './base.service';
import axiosInstance from "@/services/index";

class AddressService extends BaseService<Address> {
    getAddressesForDropDown(str: string){
        const params = {
            filter:  str
        }
        return axiosInstance.get<Address[]>(this.api + "/dropdown", {params: params});
    }
}
export const addressService = new AddressService("/addresses");
