import axiosInstance from './index';
import {CollectionViewModel, Filter, SearchResults, Sort} from "@/models/common";

interface Identifiable {
  id?: number;
}

export default class BaseService<T extends Identifiable> {

    protected api = "api"

    constructor(api: string) {
        this.api += api;
    }
  
    delete(id: number) {
      return axiosInstance.delete(this.api + '/' + id);
    }

    //не используется так как списки могут содержать много данных, избавиться
    get() {
      return axiosInstance.get<T[]>(this.api);
    }

    create(item: T) {
      return axiosInstance.post(`${this.api}`, item);
    }

    update(item: T) {
      return axiosInstance.put(this.api + '/' + item.id, item);
    }

    getSearchResult(filters: Filter[]){
        const params = {
            filters:  JSON.stringify(filters)
        }
        return axiosInstance.get<SearchResults[]>(this.api + "/search", {params: params});
    }

    getPageData(offset: number, count: number, sorts: Sort[], filters: Filter[]){
        const params = {
            offset:  offset,
            count: count,
            sorts: JSON.stringify(sorts),
            filters: JSON.stringify(filters)
        }
        return axiosInstance.get<CollectionViewModel<T>>(this.api, {params: params});
    }
}