import City from '@/models/city';
import BaseService from './base.service';
import axiosInstance from "@/services/index";

class CityService extends BaseService<City> {

    getCitiesForDropDown(str: string){
        const params = {
            filter:  str
        }
        return axiosInstance.get<City[]>(this.api + "/dropdown", {params: params});
    }
}
export const cityService = new CityService("/cities");
