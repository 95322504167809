import Company from '@/models/company';
import BaseService from './base.service';
import axiosInstance from "@/services/index";

class CompanyService extends BaseService<Company> {
    getCompaniesForDropDown(str: string){
        const params = {
            filter:  str
        }
        return axiosInstance.get<Company[]>(this.api + "/dropdown", {params: params});
    }
}
export const companyService = new CompanyService("/company");
