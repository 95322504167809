
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

export class CommentModelComponent {
	id: number;				    //ид
	bidId?: number;			  //ид заявки
	employeeId: number;		//кто написал комментарий
	employee: string;		  //
	text: string;			    //текст
  dateTime: Date;			  //дата и время написания
  isChanged: boolean;   //был ли изменен
}

@Component
export default class CommentComponent extends Vue {

  @Prop()
  readonly comment: CommentModelComponent;

  @Prop()
  readonly onRemoveComment: (comment: CommentModelComponent) => void;

  @Prop()
  readonly canEdit: (comment: CommentModelComponent) => boolean;

  private isEdit = false;
  private editedText = "";

  editComment(comment: CommentModelComponent) {
    this.isEdit = true;
    this.editedText = comment.text;
  }

  update(){
    if(this.comment.text != this.editedText){
      this.comment.isChanged = true;
      this.comment.text = this.editedText;
    }
      
      this.isEdit = false;
  }

  cancel(){
      this.isEdit = false;
  }

  convertToTimeZone(date: Date) {
    const utcDate = moment.utc(date);
    if (utcDate.isValid()) {
      return utcDate.local().format("DD.MM.YYYY, HH:mm");
    } else return "";
  }
}
