import BaseService from './base.service';
import ReasonFailure from "@/models/reason-failure";
import axiosInstance from "@/services/index";

class ReasonFailureService extends BaseService<ReasonFailure> {
    getReasonFailureForDropDown(str: string){
        const params = {
            filter:  str
        }
        return axiosInstance.get<ReasonFailure[]>(this.api + "/dropdown", {params: params});
    }

}
export const reasonFailureService = new ReasonFailureService("/reasonFailures");
