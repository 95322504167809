
import { Component, Prop, Vue } from "vue-property-decorator";
import CommentComponent, { CommentModelComponent } from './CommentComponent.vue';

@Component({
  components: {CommentComponent}
})
export default class CommentsComponent extends Vue {

  @Prop()
  readonly addComment: (str: string) => void;

  @Prop()
  readonly comments: Array<CommentModelComponent>;

  @Prop()
  readonly canEdit: (comment: CommentModelComponent) => boolean;

  private isShowAddComment = false;
  private textComment = "";

  showAddComment(){
    this.isShowAddComment = !this.isShowAddComment;
  }

  add(){
    this.addComment(this.textComment);
    this.textComment = "";
    this.showAddComment();
  }

  cancel(){
    this.showAddComment();
  }

  deleteComment(comment: CommentModelComponent){
    const index = this.comments.indexOf(comment);
    this.comments.splice(index, 1);
  }
}
