import axiosInstance from './index';
import Employee from '@/models/employee';
import BaseService from './base.service';

class EmployeeService extends BaseService<Employee>{

  getEmployeesForDropDown(roleName: string | undefined, companyId: string | undefined, filter: string){
    const params = {
      roleName: roleName,
      companyId: companyId,
      filter: filter
    }
    return axiosInstance.get<Employee[]>(this.api + "/dropdown", {params: params});
  }

  getCurrentUser(){
    return axiosInstance.get<Employee>(this.api + "/current");
  }

  usernameExists(userName: string){
    const params = {
      userName: userName
    }
    return axiosInstance.get<boolean>(this.api + "/usernameExists", {params: params});
  }

}

export const employeeService = new EmployeeService("/employees");
