import axiosInstance from './index';
import ObjectModel, { ObjectSearchResult} from '@/models/object';
import BaseService from './base.service';
import {Filter, Sort} from "@/models/common";

class ObjectService extends BaseService<ObjectModel> {

  getSearchResult(filters: Filter[]){
    const params = {
      filters:  JSON.stringify(filters)
    }
    return axiosInstance.get<ObjectSearchResult[]>(this.api + "/search", {params: params});
  }

  exportExcel(offset: number, count: number, sorts: Sort[], filters: Filter[]){
    const params = {
        offset:  offset,
        count: count,
        sorts: JSON.stringify(sorts),
        filters: JSON.stringify(filters)
    }
    return axiosInstance.get(this.api + '/Export', {params: params, responseType: 'blob'});
}

}

export const objectService = new ObjectService("/objects");
