import axiosInstance from './index';
import DashboardInfo from '@/models/dashboardInfo';

const api = 'api/Dashboard';

class DashboardService {

  getBidsInfo(year?: string, month?: string, companyId?: number | null, typeFailureId?: number | null){
    const params = {
      year: year,
      month: month,
      companyId:  companyId,
      typeFailureId: typeFailureId
    }
    return axiosInstance.get<DashboardInfo>(api + "/bidsinfo", {params: params});
  }

  getDinamicBidsMounth(year: string, month: string | undefined, companyId?: number | null, typeFailureId?: number | null){
    const params = {
      year: year,
      month: month,
      companyId:  companyId,
      typeFailureId: typeFailureId
    }
    return axiosInstance.get<BidsInfoMonth[]>(api + "/dynamic-bids-month", {params: params});
  }

  getDinamicBidsByYear(year: string, companyId?: number | null, typeFailureId?: number | null){
    const params = {
      year: year,
      companyId:  companyId,
      typeFailureId: typeFailureId
    }
    return axiosInstance.get<BidsInfoYear[]>(api + "/dynamic-bids-year", {params: params});
  }
}

export const dashboardService = new DashboardService();

export class BidsInfoMonth {
	day: number;
	count: number;
}

export class BidsInfoYear {
	mounth: number;
	count: number;
}