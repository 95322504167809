
  import MaterialCard from './MaterialCard.vue'
  import {Component, Prop, Vue} from "vue-property-decorator";

  @Component({
    components: { MaterialCard },
    inheritAttrs: false
  })
  export default class MaterialStatsCard extends Vue  {

  @Prop()
  icon: string;
    @Prop()
  subIcon: string;
    @Prop()
  subIconColor: string;
    @Prop()
  subTextColor: string;
    @Prop()
  subText: string;
    @Prop()
  title: string;
    @Prop()
  value: string;
    @Prop()
  smallValue: string;

  }

