import { BidCommentModel } from './bid-comment';
import BidPhoto from "./bid-photo";


export default class Bid {
	id?: number;							//ид
	reasonFailure?: string;				//причина неисправности
	dateTime?: Date;						//дата и время заявки
	dateTimeStart?: Date;				//дата и время начала работ
	releaseDateTimePassenger?: Date;		//дата и время освобождения пассажира
	dateTimeFinished?: Date;				//дата и время окончания работ
	objectId?: number;					//ид объекта
	object?: string;						//объект
	objectMechanic?: string;				//прикрепленный к объекту механник
	objectForeman?: string;				//прикрепленный к объекту прораб
	typeFailureId?: number;				//ид типа неисправности
	typeFailure?: string;				//тип неисправности
	executorId?: number;					//ид сотрудника выполнившего заявку
	executorRoleId?: number;			//id роль исполнителя
	executor?: string;					//сотрудник выполнивший заявку
	photos: Array<BidPhoto>;				//прикрепленные фотографии
	employeeId?: number;					//ид кто добавил заявку
	employee?: string;					//кто добавил заявку
	comments: Array<BidCommentModel>;					//комментарий
	objectCompany?: string;				//фирма объекта
	objectCompanyId?: number;				//фирма объекта
	isCompleted = false; //нужно присвоить какое-то значение чтоб vue делал его реактивным
}