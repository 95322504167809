import TypeDevice from '@/models/type-device';
import BaseService from './base.service';
import axiosInstance from "@/services/index";

class TypeDeviceService extends BaseService<TypeDevice>{
    getTypeDevicesForDropDown(str: string){
        const params = {
            filter:  str
        }
        return axiosInstance.get<TypeDevice[]>(this.api + "/dropdown", {params: params});
    }
}
export const typeDeviceService = new TypeDeviceService("/typedevices");
