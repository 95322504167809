import TypeControl from '@/models/type-control';
import axiosInstance from './index';


const api = 'api/TypeControl';

class TypeControlService {

  getTypeControl() {
    return axiosInstance.get<TypeControl[]>(`${api}`);
  }
  
}

export const typeControlService = new TypeControlService();
