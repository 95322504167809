import axiosInstance from './index';

import Employee from '@/models/employee';
import { employeeService } from './employee.service';

class AuthenticationService {

    token?: string;
    isAuthorized?: boolean;
    currentUser?: Employee | null;

    constructor() {
        const storageTokenItem = localStorage.getItem('fail-control-token');
        if (storageTokenItem) {
            const localToken = JSON.parse(storageTokenItem);
            this.token = localToken && localToken.token;
            this.isAuthorized = this.token != "" && this.token != null;
        }
    }

    async login(login: string, password: string) {
        const body = `login=${login}&password=${password}`;
        const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
        const options = {headers: headers};

        const response = await axiosInstance.post('/token', body, options)
        const result = response.data as AuthResult;
        if (result.token) {
            localStorage.setItem('fail-control-token', JSON.stringify({token: response.data.token}));
            this.token = result.token;
            this.isAuthorized = true;
        }
        return result;
    }

    logout() {
        this.token = '';
        this.isAuthorized = false;
        this.currentUser = null;
        localStorage.removeItem('fail-control-token');
    }

    async getCurrentUser() {
        if (this.currentUser) {
            return this.currentUser;
        }
        else {
            const res = await employeeService.getCurrentUser();
            this.currentUser = res.data;
            return this.currentUser;
        }
    }
}

export const authService = new AuthenticationService();

interface AuthResult {
    token: string;
    error: string;
}