import axiosInstance from './index';
import {Filter} from "@/models/common";
import { ObjectInfo, MounthReport } from '@/models/monthly-report';
import BaseService from './base.service';

class ReportService extends BaseService<ObjectInfo> {

  getMonthlyReport(filters: Filter[]) {
    const params = {
        filters: JSON.stringify(filters)
    }
    return axiosInstance.get<MounthReport>(this.api, { params: params });
  }

  exportReport(filters: Filter[]){
     const params = {
        filters: JSON.stringify(filters)
     }
     return axiosInstance.get(this.api + '/Export', { params: params, responseType: 'blob' });
  }
}

export const reportService = new ReportService("/reports");
