
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MaterialCard extends Vue {

  @Prop()
  avatar: string;
  @Prop()
  color: string;
  @Prop()
  icon: string
  @Prop()
  image: boolean;
  @Prop()
  text: string;
  @Prop()
  title: string;

      get classes () {
        return {
          'v-card--material--has-heading': this.hasHeading,
        }
      }
      get hasHeading () {
        return Boolean(this.$slots.heading || this.title || this.icon)
      }
      get hasAltHeading () {

        return Boolean(this.$slots.heading || (this.title && this.icon))
      }

  }
