

import { companyService } from "@/services/company.service";
import CompanyModel from "../models/company";
import {Component, Vue, Watch} from "vue-property-decorator";
import { VForm } from '@/types';
import { authService } from "@/services/authentification.service";
import ConfirmComponent from "@/components/ConfirmComponent.vue";
import {Filter, Sort} from "@/models/common";
import { DataOptions } from "vuetify";

@Component({
  components: { ConfirmComponent }
})
export default class City extends Vue {
  private companies: Array<CompanyModel> = new Array<CompanyModel>();
  private editedItem = new CompanyModel();
  private defaultItem = new CompanyModel();

  private displayEditDialog = false;
  private displayConfirmDialog = false;
  private editedIndex = -1;
  private totalCompanies = 0;
  private loading = true;

  private saveLoading = false;
  private deleteLoading = false;

  private pagination = {
    page: 1,
    itemsPerPage: 50,
    pageStart: 0,
    pageStop: 50,
    pageCount: 0,
    itemsLength: 0,
  };

  private options: DataOptions = {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false
  };

  private headers = [
    { text: "Название", value: "name", sortable: false },
    { text: "Активный", value: "activity", sortable: false },
    { text: "Действия", value: "actions", sortable: false }
  ];

  private valid = true;

  async mounted() {
    const employee = await authService.getCurrentUser();
    if (employee?.role?.name === "Root") {
      this.headers.unshift({text: "Ид", sortable: false, value: "id"});
    }
  }

  @Watch('options', {deep: true})
  async loadCompanies() {
    try {
      this.loading = true;

      const {page, itemsPerPage} = this.options;
      const offset = (page - 1) * itemsPerPage;
      const count = itemsPerPage == -1 ? this.totalCompanies : itemsPerPage;
      const sorts = new Array<Sort>();
      const filters = new Array<Filter>();

      this.pagination.pageStart = offset;
      this.pagination.pageStop = offset + itemsPerPage;

      const res = await companyService.getPageData(offset, count, sorts, filters);
      this.companies = res.data.collection;
      this.totalCompanies = res.data.count;
      this.pagination.itemsLength = res.data.count;
      this.pagination.pageCount = Math.ceil(res.data.count / 50);
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }

    this.loading = false;
  }

  showEditDialog(item: CompanyModel) {
    this.editedIndex = this.companies.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.displayEditDialog = true;
  }

  showDeleteDialog(item: CompanyModel) {
    this.editedIndex = this.companies.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.displayConfirmDialog = true;
  }

  async deleteCompany() {
    try {
      this.deleteLoading = true;
      const item = this.editedItem;
      await companyService.delete(item.id);
      await this.loadCompanies();
    }
    catch (ex) {
      this.$toast.error("Ошибка при удалении");
    }
    this.deleteLoading = true;
    this.close();
  }

  close() {
    this.displayConfirmDialog = false;
    this.displayEditDialog = false;
    this.form?.resetValidation();
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  async save() {
    this.form?.validate();
    if (this.valid) {
      this.saveLoading = true;
      const index = this.editedIndex;
      try {
        if (index > -1) {
          await companyService.update(this.editedItem);
        } else {
          await companyService.create(this.editedItem);
        }
        await this.loadCompanies();
      }
      catch (ex) {
        this.$toast.error("Ошибка при сохранении");
      }
      this.saveLoading = false;
      this.close();
    }
  }
}
