

import { objectService } from "@/services/object.service";
import { typeDeviceService } from "@/services/type-device.service";
import { addressService } from "@/services/address.service";
import { employeeService } from "@/services/employee.service";
import { companyService } from "@/services/company.service";
import { typeControlService } from "@/services/type-control.service";

import ObjectModel from "../models/object";
import {Filter, Sort} from "@/models/common";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TypeDevice from "@/models/type-device";
import Address from "@/models/address";
import Employee from "@/models/employee";
import Company from "@/models/company";
import { VForm } from '@/types';
import TypeControl from '@/models/type-control';

@Component
export default class ObjectEdit extends Vue {

  @Prop()
  private onUpdateList: () => void;

  private editedItem = new ObjectModel();
  private displayEditDialog = false;

  private typeDeviceLoading = true;
  private typeDevices = new Array<TypeDevice>();
  private typeDeviceFilter = null;

  private addressesLoading = true;
  private addresses = new Array<Address>();
  private addressFilter = null;

  private foremans = new Array<Employee>();
  private loadingForemans = false;
  private searchForemansInput = null;

  private mechanics = new Array<Employee>();
  private loadingMechanics = false;
  private searchMechanicsInput = null;

  private companies = new Array<Company>();
  private loadingCompanies = false;
  private companyFilter = null;

  private typeControls = new Array<TypeControl>();
  private loadingTypeControls = false;

  private saveLoading = false;
  private deleteLoading = false;

  private showFirmFilter = false;

  private valid = true;

  async loadTypeControls() {
    try {
      this.loadingTypeControls = true;
      const res = await typeControlService.getTypeControl();
      this.typeControls = res.data;
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.loadingTypeControls = false;
  }

  @Watch("typeDeviceFilter")
  async loadTypeDevices(val: string) {
    try {
      this.typeDeviceLoading = true;
      const res = await typeDeviceService.getTypeDevicesForDropDown(val);
      this.typeDevices = res.data;
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.typeDeviceLoading = false;
  }

  @Watch("addressFilter")
  async loadAddresses(val: string) {
    try {
      this.addressesLoading = true;
      const res = await addressService.getAddressesForDropDown(val);
      this.addresses = res.data;
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.addressesLoading = false;
  }

  @Watch("companyFilter")
  async loadCompanies(val: string) {
    try {
      this.loadingCompanies = true;
      const res = await companyService.getCompaniesForDropDown(val);
      this.companies = res.data;
    } catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.loadingCompanies = false;
  }

  @Watch("searchForemansInput")
  async loadForemans(val: string) {
    try {
      this.loadingForemans = true;
      const res = await employeeService.getEmployeesForDropDown("Foreman", this.editedItem.companyId?.toString(), val);
      this.foremans = res.data;
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.loadingForemans = false;
  }

  @Watch("searchMechanicsInput")
  async loadMechanics(val: string) {
    try {
      this.loadingMechanics = true;
      const res = await employeeService.getEmployeesForDropDown("Mechanic", this.editedItem.companyId?.toString(), val);
      this.mechanics = res.data;
    } catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.loadingMechanics = false;
  }

  changeCompany() {
    //при смене фирмы нужно сбросить значения связанных свойств
    this.editedItem.mechanicId = null;
    this.editedItem.foremanId = null;
    this.loadForemans("");
    this.loadMechanics("");
  }

  async showEditDialog(item: ObjectModel) {

    if(item){
      this.editedItem = Object.assign({}, item);
    }

    await this.loadTypeDevices(this.editedItem.typeDevice);
    await this.loadAddresses(this.editedItem.address);
    await this.loadCompanies(this.editedItem.company);
    await this.loadMechanics(this.editedItem.mechanic);
    await this.loadForemans(this.editedItem.foreman);
    await this.loadTypeControls();
    
    this.displayEditDialog = true;
  }

  close() {
    this.displayEditDialog = false;
    this.form?.resetValidation();
     this.$nextTick(() => {
       this.editedItem = new ObjectModel();
     });
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  async save() {
    this.form?.validate();
    if (this.valid) {
      this.saveLoading = true;
      const index = this.editedItem.id;
      try {
        if (index > 0) {
          await objectService.update(this.editedItem);
        } else {
          await objectService.create(this.editedItem);
        }
        await this.onUpdateList();
      }
      catch (ex) {
        this.$toast.error("Ошибка при сохранении");
      }
      this.saveLoading = false;
      this.close();
    }
  }
}
