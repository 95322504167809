
import Company from '@/models/company';
import DashboardInfo from '@/models/dashboardInfo';
import {Component, Vue, Watch} from "vue-property-decorator";
import {BidsInfoMonth, BidsInfoYear, dashboardService} from "@/services/dashboard.service";
import {companyService} from "@/services/company.service";
import MaterialStatsCard from "@/components/MaterialStatsCard.vue";
import MaterialCard from "@/components/MaterialCard.vue";
import LineChart from "@/components/LineChart.vue";
import moment from "moment";
import {accessSettings} from "@/settings/access.settings";
import {dashboardSettingsService} from "@/settings/dashboard.settings";
import TypeFailure from '@/models/type-failure';
import { typeFailureService } from '@/services/type-failure.service';

@Component({
  components: {MaterialStatsCard, MaterialCard, LineChart}
})
export default class Dashboard extends Vue {
  private dashboardInfo = new DashboardInfo();
  private loading = true;

  private yearItems = new Array<string>();
  private yearSelected: any = null;
  private currentYear = new Date().getFullYear().toString();

  private mounthItems = ["Все", "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
  private mounthSelected: any = null;
  private currentMounth = (this.mounthItems[new Date().getMonth() + 1]).toString();

  private companyId = null;
  private companies = new Array<Company>();
  private loadingCompanies = false;

  private showFirmFilter = false;
  private firmFilter = null;

  private typeFailures = new Array<TypeFailure>();
  private typeFailureSelected: any = null;
  private loadingTypeFailures = false;
  private currentTypeFailure = null;

  private canAddBid = false;
  private showDinamic = false;

  private loadingChart = false;

  private chartdata = {};
  private options = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: 'Динамика заявок',  //писать какой месяц или какой год
      fontColor: this.$vuetify.theme.dark ? "white" : "black",
    },
    scales: {
      yAxes: [
        {
          id: 'first-y-axis',    
          offset: true,
          ticks:{
            minor:{
              fontColor: this.$vuetify.theme.dark ? "white" : "black",
            },
            major:{
              fontColor: this.$vuetify.theme.dark ? "white" : "black",
            }
          },
          gridLines:{
            color: "Silver"
          }
        }],
        xAxes:[
        {
          id: 'first-x-axis',    
          ticks:{
            minor:{
              fontColor: this.$vuetify.theme.dark ? "white" : "black",
            },
            major:{
              fontColor: this.$vuetify.theme.dark ? "white" : "black",
            },
          },
          gridLines:{
            color: "Silver"
          }
        }
      ]
    },
    legend:{
      labels:{
        fontColor: this.$vuetify.theme.dark ? "white" : "black",
      }
    }
  }

  async getTypesFailures(){
    this.loadingTypeFailures = false;
    try{
      const result = await typeFailureService.getTypeFailuresForDropDown("");
      this.typeFailures = result.data;
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.loadingTypeFailures = false;
  }

  @Watch("$vuetify.theme.dark")
  changeTheme(){
    const fontColor = this.$vuetify.theme.dark ? "white" : "black";
    this.options.title.fontColor = fontColor;
    this.options.legend.labels.fontColor = fontColor;
    this.options.scales.yAxes[0].ticks.minor.fontColor = fontColor;
    this.options.scales.yAxes[0].ticks.major.fontColor = fontColor;
    this.options.scales.xAxes[0].ticks.minor.fontColor = fontColor;
    this.options.scales.xAxes[0].ticks.major.fontColor = fontColor;

    //this.options.scales.xAxes[0].gridLines.color = "Gainsboro";
    //this.options.scales.yAxes[0].gridLines.color = "Gainsboro";
  
    (this as any).$refs.chart?.update();
  }

  generateYears(){
    let currentYear = new Date().getFullYear();
    //дата с которой начался учет
    const startYear = 2020;
    const years = ["Все"];

    if(currentYear == startYear){
      years.push(currentYear.toString());
    }
    else{
      for (; currentYear >= startYear; currentYear--) {
        years.push(currentYear.toString());
      }
    }

    this.yearItems = years;
  }

  getBidUrl(state: string){
    let url = '/bids?';
    url += 'state=' + state;
    url += '&year=' + this.getYear;
    url += '&mounth=' + this.getMounth;

    if(this.typeFailureSelected){
      url += '&typeFailureId=' + this.typeFailureSelected.id.toString()
    }
    //bids?state=newbids&year='+ getYear + '&mounth=' + getMounth"
    //console.log(url);
    return url;
  }

  get getYear(){
    if(this.yearSelected)
      return this.yearSelected;
    return this.currentYear;
  }

  get getMounth(){
    if(this.mounthSelected)
      return this.mounthSelected;
    return this.currentMounth;
  }

  get getMounthNumber(){
    if(this.mounthSelected){
      const selectedMounth = this.mounthItems.indexOf(this.mounthSelected);
      if(selectedMounth == 0)
        return null;

      return selectedMounth;
    }
     
    return this.mounthItems.indexOf(this.currentMounth);
  }

  async mounted() {
    await this.getTypesFailures();
    this.generateYears();

    const settings = await dashboardSettingsService.getSettings();

    if(settings){
      this.yearSelected = settings?.year;
      this.mounthSelected = settings?.month;
      this.typeFailureSelected = this.typeFailures.find(item => item.id == settings.typeFailureId);
    }

    try {
      this.showFirmFilter = await accessSettings.canAccess("showFirmFilter");
      this.canAddBid = await accessSettings.canAccess("bidsAdd");
      this.showDinamic = await accessSettings.canAccess("showDynamicBids");

      await this.loadBidsInfo();

      if(this.showFirmFilter)
        await this.loadCompanies("");

      if(this.showDinamic){
        if(this.getMounthNumber)
          await this.getDinamicsBidsByMounth();
        else
          await this.getDinamicsBidsByYear();
      }
    } catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    //две выпадашки в одной год в другой месяц
    //там где год можно выбрать за все время или за конкретный год
    //там где месяц можно выбрать месяц или за все время
    //если очистить то берется текущий год и месяц
    //сохранять выбранные значения в local storage!!
  }

  async loadBidsInfo() {
    try {
      this.loading = true;
      const year = this.getYear;
      const month = this.getMounthNumber;
      const res = await dashboardService.getBidsInfo(year, month?.toString(), this.companyId, this.typeFailureSelected?.id);
      this.dashboardInfo = res.data;
    } catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.loading = false;
  }

  async changeBidsFilter() {
    this.loadBidsInfo();

    dashboardSettingsService.setSettings(this.yearSelected, this.mounthSelected, this.typeFailureSelected?.id);

    if(this.getMounthNumber)
      await this.getDinamicsBidsByMounth();
    else
      await this.getDinamicsBidsByYear();
  }

  @Watch("firmFilter")
  async loadCompanies(val: string ) {
    try {
      this.loadingCompanies = true;
      const res = await companyService.getCompaniesForDropDown(val);
      this.companies = res.data;
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
    this.loadingCompanies = false;
  }

  async getDinamicsBidsByYear(){
    try {
      const year = this.getYear;
      const dataSets = new Array<any>();
      const labels = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

      if(year == "Все"){
         for (let i = 1; i <= this.yearItems.length-1; i++) {
          const dataSet = await this.getDataSetByYear(this.yearItems[i], "заявки в " + this.yearItems[i]);
          dataSets.push(dataSet);
        }
      }
      else{
        const dataSet = await this.getDataSetByYear(year, "заявки в " + year);
        dataSets.push(dataSet);
      }

      this.chartdata = {
        labels: labels,
        datasets: dataSets
      };
      this.loadingChart = true;

    } catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
  }

  async getDataSetByYear(year: string, label: string){
    
    const bidInfo = await dashboardService.getDinamicBidsByYear(year, this.companyId, this.typeFailureSelected?.id);
    const data = this.getDataYear(bidInfo.data);

    return {
      label: label,
      backgroundColor: 'transparent',
      //borderColor:'#f87979',
      borderColor: this.getRandomColor(),
      borderWidth: 2,
      data: data 
    }
  }

  getDataYear(bidInfos: Array<BidsInfoYear>){
    const data = new Array<number>();
    for (let j = 0; j <= bidInfos.length - 1; j++) {
      const item = bidInfos[j];
      data[item.mounth - 1] = item.count;
    }
    return data;
  }

  async getDinamicsBidsByMounth() {
    try {
      const year = this.getYear;
      const month = this.getMounthNumber;
      const dataSets = new Array<any>();
      let labels = new Array<string>()

      if(year == "Все"){
        for (let i = 1; i <= this.yearItems.length-1; i++) {
          //во всех годах одинаковое количество дней в месяце (не считая високосных, но этим можно принебречь)
          if(labels.length == 0){
            const countDay = moment(this.yearItems[i] + '-' + month, "YYYY-MM").daysInMonth();
            labels = this.getLabels(countDay);
          }
          const dataSet = await this.getDataSetByMounth(this.yearItems[i], month?.toString(), "заявки в " + this.yearItems[i] + " за " + this.getMounth);
          dataSets.push(dataSet);
        }
      }
      else{
        const countDay = moment(year + '-' + month, "YYYY-MM").daysInMonth();
        labels = this.getLabels(countDay);
        const dataSet = await this.getDataSetByMounth(year, month?.toString(), "заявки " + "за " + this.getMounth);
        dataSets.push(dataSet);
      }

      this.chartdata = {
        labels: labels,
        datasets: dataSets,
      };
      this.loadingChart = true;

    } catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }
  }

  async getDataSetByMounth(year: string, month: string | undefined, label: string){
    
    const bidInfo = await dashboardService.getDinamicBidsMounth(year, month, this.companyId, this.typeFailureSelected?.id);
    const data = this.getDataMonth(bidInfo.data);

    return {
      label: label,
      backgroundColor: 'transparent',
      //borderColor:'#f87979',
      borderColor: this.getRandomColor(),
      borderWidth: 2,
      data: data 
    }
  }

  getLabels(count: number){
    const labels = new Array<string>();
      for (let j = 1; j <= count; j++) {
        labels.push(j.toString());
      }
    return labels;
  }

  getDataMonth(bidInfos: Array<BidsInfoMonth>){
     const data = new Array<number>();
      for (let j = 0; j <= bidInfos.length - 1; j++) {
        const item = bidInfos[j];
        data[item.day - 1] = item.count;
      }
      return data;
  }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  return color;
}

  
}
