<template>
  <v-app class="bg">
    <v-app-bar app clipped-right dense>
      <v-app-bar-nav-icon @click.stop="changeMiniVariant()"></v-app-bar-nav-icon>
      <v-toolbar-title>{{current}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="changeTheme()">
        <v-icon>mdi-compare</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant && !$vuetify.breakpoint.xs"
      :permanent="!$vuetify.breakpoint.xs"
      app
    >
      <v-list dense nav>
        <v-list-item two-line :class="miniVariant && 'px-0'">
          <v-list-item-avatar color="info">
            <v-icon dark>mdi-account-circle</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{fullname}}</v-list-item-title>
            <v-list-item-subtitle>{{role}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <template v-for="item in items" dense>
          <v-list-group
            v-if="item.children"
            :key="item.title"
            v-model="item.model"
            :prepend-icon="item.icon"
            mandatory
            color="info"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" :to="child.routerlink">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.title" :to="item.routerlink" mandatory color="info">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import { Component, Vue } from "vue-property-decorator";
import { authService } from "@/services/authentification.service";
import { accessSettings } from "@/settings/access.settings";

@Component
export default class Home extends Vue {
  drawer = null;
  model = null;
  items = [
    { title: "Информационная панель", icon: "mdi-view-dashboard", routerlink: "/" },
    { title: "Заявки", icon: "mdi-square-edit-outline", routerlink: "/bids" },
    { title: "Объекты", icon: "mdi-cube-outline", routerlink: "/objects" },
    { title: "Выход", icon: "mdi-logout-variant", routerlink: "/login" },
  ];

  //запоминать состояние
  miniVariant = false;
  fullname = "";
  role = "";

  current = "Журнал неисправностей";

  changeTheme(){
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    localStorage.setItem("darkTheme", this.$vuetify.theme.dark);
  }

  changeMiniVariant(){
    if(!this.$vuetify.breakpoint.xs)
      this.miniVariant = !this.miniVariant;
    else{
      this.miniVariant = false;
      this.drawer = !this.drawer;
    }
  }

  async mounted() {
    const showCatalog = await accessSettings.canAccess("showCatalog");
    const showReports = await accessSettings.canAccess("showReports");

    this.current = localStorage.getItem('fail-control-current-url');

    if(showCatalog){
      const catalog = { title: "Справочники", icon: "mdi-book-open-variant", routerlink: "",
      children: [] };
      
      if(await accessSettings.canAccess("showCompanies")){
        catalog.children.push({ title: "Фирмы", icon: "mdi-account-group", routerlink: "/company", items: { title: "test" } });
      }

      if(await accessSettings.canAccess("showCityes")){
        catalog.children.push({ title: "Города", icon: "mdi-city", routerlink: "/city", items: { title: "test" } });
      }

      if(await accessSettings.canAccess("showStreets")){
        catalog.children.push({ title: "Улицы", icon: "mdi-city-variant", routerlink: "/street" });
      }
      
      if(await accessSettings.canAccess("showAddresses")){
        catalog.children.push({ title: "Адреса", icon: "mdi-map-marker", routerlink: "/address" });
      }

      if(await accessSettings.canAccess("showEmployees")){
        catalog.children.push({ title: "Сотрудники", icon: "mdi-account", routerlink: "/employees" });
      }

      if(await accessSettings.canAccess("showTypeDevices")){
        catalog.children.push({ title: "Виды оборудования", icon: "mdi-dresser", routerlink: "/type-device"});
      }

      if(await accessSettings.canAccess("showTypeFailyres")){
        catalog.children.push({ title: "Категории неисправности", icon: "mdi-shape", routerlink: "/type-failure" });
      }

      if(await accessSettings.canAccess("showReasonFailyres")){
        catalog.children.push({ title: "Причины неисправности", icon: "mdi-format-list-bulleted-type", routerlink: "/reason-failure" });
      }

      if(await accessSettings.canAccess("showContacts")){
        catalog.children.push({ title: "Контакты", icon: "mdi-phone", routerlink: "/contacts" });
      }

      this.items.splice(3, 0, catalog);
    }

    this.$router.afterEach((to, from) => {
      to.matched.some(record => {
        if(record.meta.Name){
          this.current = record.meta.Name;
        }
      });
      localStorage.setItem('fail-control-current-url', this.current);
    });

    if(showReports){
      const index = showCatalog ? 4 : 3;
      this.items.splice(index, 0, { title: "Отчеты", icon: "mdi-file-document-multiple-outline", routerlink: "/",
      children: [
        { title: "Месячный отчет", icon: "mdi-file-document-outline", routerlink: "/monthly-report"},
      ],
    });
    }

    authService.getCurrentUser().then((res) => {
       this.fullname = "";
       if(res.surname)
        this.fullname += res.surname + " ";

       if(res.name)
        this.fullname += res.name.slice(0, 1).toUpperCase() + ". ";

       if(res.patronymic)
        this.fullname += res.patronymic.slice(0, 1).toUpperCase() + ".";

       this.role = res.role.displayName;
    });
  }
}
</script>
<style scoped>

</style>