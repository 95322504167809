import TypeFailure from '@/models/type-failure';
import BaseService from './base.service';
import axiosInstance from "@/services/index";

class TypeFailureService extends BaseService<TypeFailure>{
    getTypeFailuresForDropDown(str: string){
        const params = {
            filter:  str
        }
        return axiosInstance.get<TypeFailure[]>(this.api + "/dropdown", {params: params});
    }

}
export const typeFailureService = new TypeFailureService("/typefailures");
