
import { ObjectInfo } from "@/models/monthly-report";
import {Component, Vue, Watch} from "vue-property-decorator";
import { reportService } from "@/services/report.service";
import Company from "@/models/company";
import { companyService } from "@/services/company.service";
import {Filter, Sort} from "@/models/common";
import {accessSettings} from "@/settings/access.settings";
import moment from 'moment';

@Component
export default class Report extends Vue {
  private objects: Array<ObjectInfo> = new Array<ObjectInfo>();
  private days: number[];
  private headers = Array<any>();

  private totalObjects = 0;
  private loading = true;

  private bidsdatefilter = moment().local().format("YYYY-MM-DD, HH:mm").substr(0, 7);
  private bidsdatefiltermenu = false;

  private options = {};

  private companyId: any = null;
  private companies = new Array<Company>();
  private loadingCompanies = false;
  private firmFilter = null;

  private loadingExcel = false;
  private showFirmFilter = false;

  private searchAddress = "";

  async mounted() {
    this.showFirmFilter = await accessSettings.canAccess("showFirmFilter");

    await this.loadReportData();
    await this.loadCompanies("");
  }

  getFilters(){
    const year = this.bidsdatefilter.slice(0, 4);
    const month = this.bidsdatefilter.slice(5, 7);
    const filters = new Array<Filter>();
    if (this.companyId)
      filters.push({field:"companyId", value: this.companyId})

    if (this.searchAddress)
      filters.push({field:"address", value: this.searchAddress})

    filters.push({field: "year", value: year});
    filters.push({field: "month", value: month});

    return filters;
  }

  @Watch("searchAddress")
  async loadReportData() {
    const headers = [];
    this.loading = true;
   
    const filters = this.getFilters();
    try {
      const res = await reportService.getMonthlyReport(filters);

      this.objects = res.data.objects;
      this.totalObjects = res.data.objects.length;
      this.days = res.data.days;

      if(this.showFirmFilter)
        headers.push({ text: "Фирма", align: "start", sortable: false, value: "company" });

      headers.push({ text: "Адрес", align: "start", sortable: false, value: "address" });
      headers.push({text: "Объект", value: "info", sortable: false});

      for (let j = 0; j < res.data.days.length; j++) {
        headers.push({text: res.data.days[j], sortable: false});
      }

      headers.push({ text: "Всего заявок", value: "countBidsInMonth", sortable: false });
    }
    catch (ex) {
      this.$toast.error("Ошибка при создании отчета");
    }

    this.headers = headers;
    this.loading = false;
  }

  @Watch("firmFilter")
  async loadCompanies(val: string) {
    try {
      this.loadingCompanies = true;
      const res = await companyService.getCompaniesForDropDown(val);
      this.companies = res.data;
    }
    catch (ex) {
      this.$toast.error("Ошибка при загрузке данных");
    }

    this.loadingCompanies = false;
  }

  changeFilter(bidsdatefilter: string){
    (this as any).$refs.bidsdatefiltermenu.save(bidsdatefilter);
    this.loadReportData();
  }

  changeCompany(){
    this.loadReportData();
  }

  async excelExport() {
    this.loadingExcel = true;
    const filters = this.getFilters();

    try {
      const response = await reportService.exportReport(filters);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'МесячныйОтчет.xlsx');
      document.body.appendChild(link);
      link.click();
    }
    catch (ex) {
      this.$toast.error("Ошибка при экспорте");
    }
    this.loadingExcel = false;
  }
}
