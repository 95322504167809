import {authService} from "@/services/authentification.service";

//содержит все права даступа по ролям
export default class AccessSettings {

    private accessRulesMap: Record<string, Set<string>> = {
        //заявки
        "bidsAdd": new Set<string>(["Admin", "Manager", "Dispatcher", "Foreman", "Service-Engineer"]),
        "bidsEditBaseInfo": new Set<string>(["Admin", "Manager", "Dispatcher", "Foreman", "Service-Engineer"]),
        "bidsConfirm": new Set<string>(["Admin", "Manager", "Dispatcher", "Foreman"]),
        "showDynamicBids": new Set<string>(["Admin", "Manager", "Dispatcher"]),
        "createBidWithoutMechanic": new Set<string>(["Admin", "Manager"]),
        //объекты
        "enabledEditObject": new Set<string>(["Admin"]),
        //общее
        "showFirmFilter": new Set<string>(["Admin", "Dispatcher"]),
        //"showID": new Set<string>(["Root"]),
        "showReports": new Set<string>(["Admin", "Manager", "Foreman"]),

        "showCatalog": new Set<string>(["Admin", "Dispatcher"]),
        "showCompanies": new Set<string>(["Admin"]),
        "showCityes": new Set<string>(["Admin"]),
        "showStreets": new Set<string>(["Admin"]),
        "showAddresses": new Set<string>(["Admin"]),
        "showEmployees": new Set<string>(["Admin"]),
        "showTypeDevices": new Set<string>(["Admin"]),
        "showTypeFailyres": new Set<string>(["Admin"]),
        "showReasonFailyres": new Set<string>(["Admin"]),
        "showContacts": new Set<string>(["Admin", "Dispatcher"]),
        "editContacts": new Set<string>(["Admin"]),
        
        "showTypeControl": new Set<string>(["Admin"]),

    };

    async canAccess(item: string){
        const user = await authService.getCurrentUser();

        if(user.role?.name === "Root"){
            return true;
        }

        if(user.role?.name === "Admin" && item !== "showID"){
            return true;
        }

        const role = user.role.name;
        if(this.accessRulesMap[item] && this.accessRulesMap[item].has(role)){
            return true;
        }

        return false;
    }
}

export const accessSettings = new AccessSettings();