
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ConfirmComponent extends Vue {

  @Prop()
  private displayConfirmDialog = false;

  @Prop()
  private text: string;

  @Prop()
  readonly onCancel: () => void;

  @Prop()
  readonly onConfirm: () => void;

  @Prop()
  deleteLoading: boolean;

}
