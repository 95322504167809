import Street from '@/models/street';
import BaseService from './base.service';
import axiosInstance from "@/services/index";

class StreetService extends BaseService<Street>{

    getStreetsForDropDown(str: string){
        const params = {
            filter:  str
        }
        return axiosInstance.get<Street[]>(this.api + "/dropdown", {params: params});
    }
}
export const streetService = new StreetService("/streets");
